<template>
  <Page id="app" currentLink="home">
    <div class="desktop">
      <div class="heading">
        <div
            :class="['background', backgroundLoaded ? 'fade-in-background' : '']"
        ></div>
        <div :class="['spiral', spiralLoaded ? 'fade-in-spiral' : '']"></div>

        <!-- Vertically positioned words with fade-in effect -->
        <div class="words">
          <div
              v-for="(word, index) in words"
              :key="index"
              :class="{
                'fade-in': wordsLoaded,
                'hidden': !wordsLoaded,
                'word-outer': true,
                }"
              :style="{
            'animation-delay': `${index}s`,
            'padding-left': `${index * 64}px`,
          }"
          >
            <a class="word">{{ word }}</a>
          </div>
        </div>
      </div>

      <WatermarkSection
          style="
        position: absolute;
        top: 1000px;
        left: 0;
        right: 0;
        height: 535.25px;
        z-index: -1;
      "
      ></WatermarkSection>

      <div class="text-block" style="width: 472px; margin: auto;">
        <HomeContent1/>
      </div>

      <div style="height: 54px"></div>
      <Banner></Banner>
      <div style="height: 54px"></div>

      <div class="column">
        <div class="image-column">
          <img src="/book-cover.png" alt="Book Cover"/>
        </div>
        <div class="content-column">
          <div class="title">
            <img src="/heading-books.png" alt="Books Heading"/>
          </div>
          <div class="text-block" style="width: 314px">
            <HomeContent2/>
          </div>
          <a href="/books" class="button">BOOK OPTIONS</a>
        </div>
      </div>
      <div>
        <HomeContent3/>
      </div>

      <CreamSection>
        <div class="column" style="position: relative">
          <div class="content-column">
            <div class="title">
              <img src="/heading-materials.png" alt="Materials Heading"/>
            </div>
            <div class="text-block" style="width: 305px; margin-left: 30px">
              <HomeContent4/>
            </div>
            <a href="/materials" class="button">FIND OUT MORE</a>
          </div>
          <div class="image-column">
            <img src="/poster.png" alt="Poster" class="poster"/>
          </div>
        </div>
      </CreamSection>

      <div style="height: 20px;"></div>
      <div class="column" style="position: relative">
        <div class="content-column">
          <div class="title-lg">
            <img src="/heading-as-featured-in.png" alt="As Featured In"/>
          </div>
          <HomeContent5/>
        </div>
      </div>
      <div style="height: 70px;"></div>

      <div class="form col-center">
        <div class="title-sm">
          <img src="/heading-contact.png" alt="Contact Heading"/>
        </div>
        <p>
          If you’d like to leave a message, please feel free to send us an email.
        </p>
        <ContactForm/>
      </div>
    </div>
    <div class="mobile">
      <div class="heading" style="position: relative">
        <div
            :class="['background', backgroundLoaded ? 'fade-in-background' : '']"
        ></div>
        <div :class="['spiral', spiralLoaded ? 'fade-in-spiral' : '']"></div>

        <Logo class="logo"></Logo>

        <!-- Vertically positioned words with fade-in effect -->
        <div class="words">
          <div
              v-for="(word, index) in words"
              :key="index"
              :class="{
                'fade-in': wordsLoaded,
                'hidden': !wordsLoaded,
                'word-outer': true,
                }"
              :style="{
            'animation-delay': `${index}s`,
            'padding-left': `${index * 8}vw`,
          }"
          >
            <a class="word">{{ word }}</a>
          </div>
        </div>
      </div>


      <div class="text-block-col">
        <HomeContent1/>
      </div>

      <div style="height: 30px"></div>
      <Banner></Banner>
      <div style="height: 30px"></div>

      <div class="col-center">
        <div class="title">
          <img src="/heading-books.png" alt="Books Heading"/>
        </div>
        <img src="/book-cover.png" alt="Book Cover"/>
        <div class="text-block">
          <HomeContent2/>
        </div>
        <a href="/books" class="button">BOOK OPTIONS</a>
      </div>

      <div style="height: 60px"></div>

      <div class="col-center">
        <HomeContent3/>
      </div>

      <CreamSection>
        <div class="col-center">
          <div class="title">
            <img src="/heading-materials.png" alt="Materials Heading"/>
          </div>
          <div class="text-block">
            <HomeContent4/>
          </div>
          <img src="/poster.png" alt="Poster" class="poster"/>
          <a href="/materials" class="button">FIND OUT MORE</a>
        </div>
      </CreamSection>

      <div style="height: 20px;"></div>
      <div class="col-center">
        <div class="title-lg">
          <img src="/heading-as-featured-in.png" alt="As Featured In"/>
        </div>
        <div style="height: 20px;"></div>
        <HomeContent5/>
      </div>
      <div style="height: 70px;"></div>

      <div class="form col-center">
        <div class="title-sm">
          <img src="/heading-contact.png" alt="Contact Heading"/>
        </div>
        <p>
          If you’d like to leave a message, please feel free to send us an email.
        </p>
        <ContactForm/>
      </div>

    </div>
  </Page>
</template>

<script setup lang="ts">

import HomeContent1 from "~/components/index/HomeContent1.vue";
import HomeContent3 from "~/components/index/HomeContent3.vue";
import HomeContent4 from "~/components/index/HomeContent4.vue";
import HomeContent5 from "~/components/index/HomeContent5.vue";
import HomeContent2 from "~/components/index/HomeContent2.vue";

const words = ref(["awareness", "resilience", "joy"]);
const backgroundLoaded = ref(false);
const spiralLoaded = ref(false);
const wordsLoaded = ref(false);

onMounted(() => {
  const backgroundImage = new Image();
  backgroundImage.src = "/bg-home.png";
  backgroundImage.onload = () => {
    backgroundLoaded.value = true;
    const spiralImage = new Image();
    spiralImage.src = "/spiral.png";
    spiralImage.onload = () => {
      spiralLoaded.value = true;
      // Delay words animation by another 1 second after spiral has loaded.
      setTimeout(() => {
        wordsLoaded.value = true;
      }, 200);
    };
  };
});
</script>

<style scoped>
.desktop {
  .heading {
    height: 475px;
  }

  .background {
    background-image: url("/bg-home.png");
    position: absolute;
    top: 34px;
    right: 0px;
    width: 959.5px;
    height: 653px;
    background-size: 100% 100%;
    opacity: 0.0;
  }


  .spiral {
    background-image: url("/spiral.png");
    position: absolute;
    top: 0;
    left: 454px;
    width: 423.75px;
    height: 567.75px;
    background-size: 100% 100%;
    opacity: 0.0;
  }

  .words {
    position: absolute;
    top: 237.7225px;
    left: 122.375px;
    font-family: Palatino, serif;
    line-height: 35px;
  }
}

.word {
  font-family: Palatino, serif;
  letter-spacing: 8.5px;
  font-size: 33px;
}


.mobile {

  .logo {
    height: 19vw;
    width: 40vw;
    top: -3vw;
    position: absolute;
    left: 10vw;
  }
  .heading {
    height: 70vw;
  }

  img {
    max-width: 100vw;
    max-height: 70vw;
  }

  .background {
    background-image: url("/bg-home.png");
    opacity: 0.0;
    background-size: 100% 100%;
    width: calc(100vw);
    height: calc(100vw * 0.68);
    z-index: 0;

  }

  .spiral {
    position: absolute;
    background-image: url("/spiral.png");

    width: calc(44vw);
    height: calc(44vw * 1.33);

    top: -4vw;
    left: calc(45vw);

    background-size: 100% 100%;
    z-index: 1;
  }

  .words {
    padding: 0;
    margin: 0;
    position: absolute;
    font-family: Palatino, serif;
    line-height: 0.1;
    font-size: 3.5vw;
    top: 20vw;
    left: 10vw;
  }

  .word {
    padding: 0;
    margin: 0;
    line-height: 0.2;
    letter-spacing: 0.3em;
    font-size: 3.5vw;
  }

  .word-outer {
    padding: 0;
    margin: 0;
    line-height: 2;
    letter-spacing: 0.3em;
    font-size: 3.5vw;
  }
}


.text-block {
  width: 100%;
  text-align: center;
}

.mobile .text-block-col {
  width: calc(100% - 80px);
  padding: 0 40px;
  text-align: center;

}

.fade-in-background {
  animation: fadeIn 1s forwards;
}

.fade-in-spiral {
  animation: fadeIn 1s 0.2s forwards;
}


.fade-in {
  animation: fadeInSlideIn 2s ease-out forwards;
  font-size: 33pt;
  color: #4498b9;
  padding-bottom: 20px;
  opacity: 0.0;
  font-family: Palatino, serif;
}

@keyframes fadeInSlideIn {
  0% {
    opacity: 0.0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1.0;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.0;
  }
  100% {
    opacity: 1.0;
  }
}

.column {
  display: flex;
  text-align: center;
  margin: 0 auto;
  justify-content: center;
  align-items: flex-start;
}

.image-column {
  margin-top: -10px;
  margin-left: 40px;
  position: relative;
  width: 361.25px;
}

.image-column img {
  max-width: 100%;
}

.content-column {
  p {
    text-align: left;
  }
}


</style>
