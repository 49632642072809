<template>
  <p>
    The drawings from the book are available in a larger poster format
    for you to download as a PDF and print out for future
    conversations with your kids.
  </p>

  <p>
    There are a range of posters covering topics such as positive
    self-talk, fears and anxieties, individuality, comparisons,
    sharing problems and ways to create a more balanced life, to name
    a few. Each poster includes talk-tips for parents and caregivers
    to help guide the conversations and promote openness.
  </p>
  <p>
    Hang them up on your wall as your own Spiral Up discussion
    gallery!
  </p>
</template>
