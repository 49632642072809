<template>

  <p>
    Parenting is wonderful but challenging.<br/>
    We can all use a little help occasionally in bringing our children
    into the world with more awareness, resilience and joy.
  </p>
  <p>
    Spiral Up Parenting offers unique ideas on ways to talk to your kids
    about
    life’s challenges through the use of simple, metaphorical drawings and
    concepts,
    providing them with memorable coping strategies for “outside the front
    gate”.
    Our aim is to help kids make positive, conscious choices within their
    lives,
    empowering them to “spiral up” and live the best version of themselves
    in the world.
  </p>
</template>
