<template>
  <div class="quote">“This essential reading is a godsend for parents...”</div>
  <div class="quote-source">Georgina Manning - Director of Wellbeing for Kids, creator of Peaceful Kids Programs</div>
  <div class="quote">“...a beautiful opportunity for parents and children to connect...”</div>
  <div class="quote-source">Lael Stone - Speaker, educator and co-author of Raising Resilient and Compassionate
    Children
  </div>
  <div class="quote">“This book is phenomenal!”</div>
  <div class="quote-source">Katy Dawson - Marketing Advisor, mother of three</div>

</template>
