<template>
  <p>
    <em>Spiral Up to Empower Resilient Kids</em> is a refreshing
    collection of visual, mindful parenting ideas and learnt gems of
    wisdom to help your family thrive.
  </p>
  <p>
    This really is a “from one parent to another” type of parenting
    book. One you can pick up amongst your busy life and easily gain
    some practical insights on <em>how</em> to talk to your kids about
    important issues.
  </p>
  <p>Take a moment. Share, listen, connect. And Spiral Up.</p>
</template>
